<template>
    <div class="video_main">
        <div class="video_box">
            <div class="video_main1">
                <div id="video-container0" style="height: 100%"></div>
            </div>
            <div class="video_main1">
                <div id="video-container1" style="height: 100%"></div>
            </div>
        </div>
        <div class="pagination">
            <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :current-page.sync="currentPage"
                    :page-size="2"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getVideoById } from '@/api/pumpStation.js'
export default {
  data () {
    return {
      // 对ezuikit萤石云视频的操作类
      player: [1, 2],
      // 视频的播放地址源
      playerList: [],
      page: 1,
      size: 2,
      total: 0,
      staId: '',
      currentPage: 1
    }
  },
  watch: {
    $route: 'getVideo'
  },
  created () {
    // this.staId = this.$route.params.staId
    // this.getVideo(this.$route.params.staId, this.page, this.size)
    this.getVideo()
  },
  methods: {
    clear () {
      this.currentPage = 1
      this.page = 1
      this.size = 2
    },
    getVideo () {
      const video = document.querySelector('#video-container0')
      const video1 = document.querySelector('#video-container1')
      if (video && video1) {
        video.innerHTML = ''
        video1.innerHTML = ''
      }
      this.clear()
      const id = this.$route.params.id
      getVideoById({ id: id, page: this.page, size: this.size }).then(res => {
        this.total = res.data.total
        this.playerList = res.data.list.map(item => {
          return {
            accessToken: item.accessToken,
            url: item.videoAddress
          }
        })
        if (this.playerList.length != 0) {
          for (const i in this.player) {
            for (const j in this.playerList) {
              if (i == j) {
                this.player[i] = new this.$ezuikit.EZUIKitPlayer({
                  id: `video-container${i}`, // 视频容器ID
                  templete: 'standard',
                  header: ['capturePicture', 'save'],
                  footer: ['hd', 'fullScreen'],
                  accessToken: this.playerList[j].accessToken,
                  url: this.playerList[j].url
                })
              }
            }
          }
        } else {
          video.innerHTML = ''
          video1.innerHTML = ''
        }
      })
    },
    handleCurrentChange (val) {
      const video = document.querySelector('#video-container0')
      const video1 = document.querySelector('#video-container1')
      if (video && video1) {
        video.innerHTML = ''
        video1.innerHTML = ''
      }
      getVideoById({ id: this.$route.params.id, page: val, size: this.size }).then(res => {
        this.total = res.data.total
        this.playerList = res.data.list.map(item => {
          return {
            accessToken: item.accessToken,
            url: item.videoAddress
          }
        })
        console.log(this.playerList, video)
        if (this.playerList.length != 0) {
          for (const i in this.player) {
            for (const j in this.playerList) {
              if (i == j) {
                setTimeout(() => {
                  this.player[i] = new this.$ezuikit.EZUIKitPlayer({
                    id: `video-container${i}`, // 视频容器ID
                    templete: 'standard',
                    header: ['capturePicture', 'save'],
                    footer: ['hd', 'fullScreen'],
                    accessToken: this.playerList[j].accessToken,
                    url: this.playerList[j].url
                  })
                }, 700)
              }
            }
          }
        } else {
          video.innerHTML = ''
          video1.innerHTML = ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video_main{
    color: #333333;
    display: flex;
    flex-direction: column;
    .video_box{
        display: flex;
        justify-content: space-between;
        .video_main1{
            width: 755px;
            height: 536px;
            /*background: #333333;*/
            background: url("~@/assets/images/index/video.png") no-repeat;
            background-position: center;
        }
    }
    .pagination{
        margin-top: 10px;
    }
}
</style>
