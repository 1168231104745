<template>
    <div class="pumpStation">
        <div class="pump_top Card-b-c">
            <div class="pump_top_title">
                <span class="title">请选择设备：</span>
                <div class="radio">
                    <el-radio-group v-model="radio" @change="changeRadio" v-if="radioList.length != 0">
                        <el-radio v-for="(item,index) in radioList" :label="index" :key="index">{{item.staName||'设备'+item.staId}}</el-radio>
                    </el-radio-group>
                    <span class="title" v-else>无设备信息</span>
                </div>
            </div>
            <div class="pump_top_main">
                <ul class="info_list">
                    <li class="info_item">瞬时流量：<span class="text">{{topInfo.instantaneousDelivery || '0'}}(m³/h)</span></li>
                    <li class="info_item">A相电压：<span class="text">{{topInfo.aPhaseVoltage || '0'}}V</span></li>
                    <li class="info_item">B相电压：<span class="text">{{topInfo.bPhaseVoltage || '0'}}V</span></li>
                    <li class="info_item">C相电压：<span class="text">{{topInfo.cPhaseVoltage || '0'}}V</span></li>
                    <li class="info_item">累计流量：<span class="text">{{topInfo.cumulativeDischarge || '0'}}(m³)</span></li>
                </ul>
                <ul class="info_list">
                    <li class="info_item">总有功电能：<span class="text">{{topInfo.totalPower || '0'}}(kW.h)</span></li>
                    <li class="info_item">A相电流：<span class="text">{{topInfo.aPhaseCurrent || '0'}}A</span></li>
                    <li class="info_item">B相电流：<span class="text">{{topInfo.bPhaseCurrent || '0'}}A</span></li>
                    <li class="info_item">C相电流：<span class="text">{{topInfo.cPhaseCurrent || '0'}}A</span></li>
                    <li class="info_item">水泵开关<span class="text">
                        <!-- <el-switch
                             width="60"
                            v-model="value"
                            @change="changeRelay"
                            :active-text="showName"
                            active-color="#13ce66"
                            inactive-color="#dcdfe6"
                            :disabled="topInfo.type == 0"
                            :active-value="0"
                            :inactive-value="1">
                        </el-switch> -->
                        <!-- <el-radio-group v-model="value" :disabled="topInfo.type == 0" @change="changeRelay" >
                          <el-radio :label="1" border>关闭</el-radio>
                          <el-radio :label="0" border>开启</el-radio>
                        </el-radio-group> -->
                        <span v-if='topInfo.relay==0' style="color:#0EBF4D">(已开启)</span>
                        <span v-if='topInfo.relay==1'  style="color:#ADADAD">(已关闭)</span>
                        <span class="texts">：</span>
                        <el-button
                          type="primary"
                          class="btn"
                          @click="changeRelay(0)"
                          :disabled="topInfo.type == 0 ? true : false"
                          >开启</el-button
                        >
                        <el-button
                          @click="changeRelay(1)"
                          style="margin: 0 5px;"
                           class="btn"
                          :disabled="topInfo.type == 0 ? true : false"
                          >关闭</el-button
                        >
                    </span></li>
                </ul>
                <a class="bg_img">
                    <img src="@/assets/images/bgPump.png" alt="">
                </a>
            </div>
        </div>
        <div class="pump_video Card-b-c">
            <Titles Titles="监控视频" />
            <div class="pump_video_main">
                <VideoPump></VideoPump>
            </div>
        </div>
    </div>
</template>

<script>
import { getPumpStationById, setPumpStation, getPumpStationItemIdByPid } from '@/api/pumpStation.js'
import Titles from '@/components/Titles'
import VideoPump from './components/video'
export default {
  data () {
    return {
      value: 1,
      topInfo: {},
      radio: 0,
      radioList: []
    }
  },
  components: {
    Titles,
    VideoPump
  },
   computed: {
    showName() {
      return this.value == '1' ? 'OFF' : 'ON'
    }
  },
  watch: {
    $route (to, from) {
       this.radio=0
      this.getData(to.params.id)
    }
  },
  created () {

    this.getData(this.$route.params.id)
  },
  methods: {
    getData (id) {
      getPumpStationItemIdByPid({ pid: id }).then(res => {
        // console.log(res)
        if (res.code == 200) {
          if (res.data.length != 0) {
            this.radioList = res.data
            this.topInfo = res.data[this.radio]
          } else {
            this.radioList = []
            this.topInfo = { type: 0 }
          }
        }
      })
      // getPumpStationById({ id }).then(res => {
      //   console.log(res)
      //   this.topInfo = res.data
      //   const obj = Object.assign({}, this.topInfo)
      //   this.value = obj.relay
      // })
    },
    changeRadio (val) {
      this.topInfo = this.radioList[val]
    },
    changeRelay (val) {
      setPumpStation({ relay: val, staId: this.topInfo.staId }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getData(this.$route.params.id)
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
  ::v-deep .el-switch {
    position: relative;
    height: 24px;
    line-height: 24px;
    &.is-checked {
      .el-switch__core {
        background: #179bff;
        &:after {
          margin-left: -22px;
        }
      }
    }
    .el-switch__label,
    .el-switch__core {
      height: 24px;
    }
    .el-switch__core {
      border-radius: 12px;
      border: none;
      background: #f1f1f1;
      &:after {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
        width: 22px;
        height: 22px;
      }
    }
    .el-switch__label.is-active {
      color: #333;
    }
    .el-switch__label--right {
      margin-left: 0px;
      &.is-active {
        color: #fff;
        right: 24px;
      }
      position: absolute;
      right: 8px;
      top: -1px;
      color: #999;
      > span {
        font-size: 12px;
      }
    }
  }
  .btn{
    position: relative;
    z-index: 20;
  }
  .texts{
    font-weight: 400;
    color: #666666;
  }
</style>
