import { get, post } from '../common/axiosHttp.js'

// 地图站点列表
export const getPumpStationById = (params) => get('/pumpStation/getPumpStationById', params)

// 视频
export const getVideoById = (params) => get('/pumpStation/getPumpVideoByStaId', params)

// 继电器开关
export const setPumpStation = (params) => post('/pumpStation/setPumpStation', params)

export const getPumpStationItemIdByPid = (params) => get('/pumpStationItem/getPumpStationItemIdByPid', params)
